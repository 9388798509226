.homePage{
  width:100%;
  height:160vh;
  background-color:white;
}

.secondbar{
  height:6vh;
  display:flex;
  flex-direction:column;
  justify-content: center;
}

.secondbar ul{
  display:flex;
  justify-content: space-around;
  list-style: none;
  color:rgb(36, 99, 162);
  padding:0 5vw;
  font-weight: bold;
}

.slideshow{
  position:relative;
  width:100%;
  height:80vh;
  background-size:cover;
  background-position:center;
  background-color:rgb(197, 197, 195);
  background-repeat:no-repeat;
}

.splash{
  position:relative;
  width:100%;
  height: 65vh;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}

.emperor{
    background-image:url("./images/emperor.png");
    background-size:contain;
}

.dcolor{
    background-image:url("./images/dcolor.jpg");
    background-size:contain;
}

.klabs{
    background-image:url("./images/kriztolabbanner.jpg");
    background-size:contain;
}

.gia-logo{
  position:absolute;
  right:calc(180px + 5vw);
  top:55vh;
  /* left:14.5vw; */
  /* top:40vh; */

}

.slideshow-overlay{
  position:absolute;
  background-color:rgba(255,255,255,0.6);
  padding:1vh 2vw;
  width:36vw;
  height:20vh;
  color:rgba(3, 10, 70,0.9);
  left:5vw;
  top:12.5vh;
  display:flex;
  flex-direction:column;
  justify-content: center;
}

.slideshow-overlay h3{
  font-size:calc(1.5vw + 1.5vh);
}

.slideshow-button{
  position:absolute;
  left:14vw;
  margin-top:7.5vh;
  color:white;
  background-color:rgba(3, 10, 70,0.9);
  padding:1vh 2vw;
  border:none;
  border-radius:5px;
}

.slideshow-button:hover{
  background-color:rgba(3, 10, 70,0.6);
  cursor:pointer;
}

.slideshow-button:focus{
  outline:none;
}

.no-padding{
  padding:0px;
}

.slideshow-img{
  width:100%;
  height:100%;
  object-fit: cover;
  object-position:50% 30%;
}

.slideshow a{
  position:absolute;
  top:50%;
}

.slideshow a:visited{
  text-decoration:none;
  color:white;
}

.slideshow a:hover{
  text-decoration:none;
  color:white;
  cursor:pointer;
}

.home-description{
  height:80vh;
  color:rgb(3,10,45);
  background-color:white;
}

.about{
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  overflow: hidden;
}

.desc-col{
  display:flex;
  justify-content:center;
  flex-direction:column;
}

.about-desc{
  padding:5vh 12vw 5vh 12vw;
  font-weight:bold;
  font-size:calc(1vw + 1vh);
}

.carousel-wrap{
  display:flex;
  justify-content: center;
  height:50%;
  background-color:black;
}
.carousel-img{
  width:50%;
}

.contact-wrap{
  background-color:grey;
  padding-top:15vh;
  padding-bottom:15vh;
  text-align: left;
  padding-left:10vw;
  color:white;
}

.contact{
  padding-bottom:2vh;
}
