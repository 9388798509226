.header{
  width:100%;
  min-height:15vh;
  display:flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color:rgb(160, 195, 212);
  overflow:hidden;
  font-weight:bold;
}



.navButton{
  opacity:0.5;
}

.is-active{
  opacity:1;
}

.navButton:hover{
  opacity:1;
}


.logo{
  min-width:80px;
  max-width: 20vw;
  min-height:10vh;
  max-height: 12vh;
  height: 100%;
}

.header-wrapper{
  display:flex;
  justify-content: space-between;
  padding:0 5vw;
}

.navbar-wrapper{
  display:flex;
  justify-content: center;
  flex-direction: column;
}

.navbar{
  display:flex;
  list-style: none;
}

.navbar li{
  padding:10px;
}

.navbar a {
  text-decoration:none;
  font-size:16px;
}

.navbar a:visited{
  color:rgb(36, 99, 162);
}

.navbar a:hover{
  cursor:pointer;
  color:rgb(160, 195, 212);
  transition-duration:0.2s;
}
